@tailwind base;
@tailwind components;
@tailwind utilities;
/***************
GOOGLE FONT
*****************/
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&family=Open+Sans:ital,wght@0,300;0,500;1,300;1,500&display=swap');
/**************  
VARIABLES
***************/
$default:#1c3277;
$accent:#0099b1;
$lightAccent:#d0f9ff;
$turquoise: #0099b1;
$grey:#C4C4C4;
$lightGrey:#EBEBEB;
$darkGrey:#2E2E2E;
$headFont:  'Libre Caslon Text', serif;
$defaultFont: 'Open Sans', sans-serif;

h1,h2,h3,h4,h5,h6 {
    font-family:$headFont;
    text-transform:uppercase;
    line-height:1.6;
    color:$default;
    letter-spacing: 1px;
}
body,p,li,a {
    font-family:$headFont;
    font-weight:300;
    color:$default;
}
body {
    position:relative;
}
#result-text {
    &.invalid {
        color:red;
        font-family:$defaultFont;
    }
}
#bg-image {
    background-image:url('/jillenpieter.jpg');
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
}